import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import * as React from 'react';

import { ConfigProps, withConfig } from 'src/app/components/WithConfig';
import { HorizontalProfileNavigationBar } from '../ProfileNavigationBar/ProfileNavigationBar';

type Props = ConfigProps;

@withStyles(require('./HorizontalNavigationBar.scss'))
class HorizontalNavigationBarContainer extends React.Component<Props> {
  render() {
    return (
      <div className="HorizontalNavigationBar">
        <div className="HorizontalNavigationBar__menu HorizontalNavigationBar__menu--right">
          <HorizontalProfileNavigationBar />
        </div>
      </div>
    );
  }
}

export const HorizontalNavigationBar = () =>
  withConfig({
    Component: HorizontalNavigationBarContainer
  });
