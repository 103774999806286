import gql from 'graphql-tag';

export const TAKE_BREAK_QUERY = gql`
  mutation takeBreak {
    takeBreak
  }
`;

export interface TakeBreakQueryResult {
  takeBreak?: Date;
}

export const BACK_FROM_BREAK_QUERY = gql`
  mutation backFromBreak {
    backFromBreak
  }
`;

export interface BackFromBreakQueryResult {
  backFromBreak?: boolean;
}
