import * as React from 'react';
import { BroadcastContactIcon } from 'src/app/components/Icons';
import { BROADCAST, CONTACT } from 'src/app/constants/route';
import { SidebarItem } from '../sidebar';

const CONTACT_SIDEBAR: SidebarItem = {
  authorityIds: [CONTACT.requiredAuthority!],
  iconName: 'ic-contact',
  id: 'contact',
  labelId: 'sidebar.contact',
  url: CONTACT.path
};

const BROADCAST_SIDEBAR: SidebarItem = {
  authorityIds: [BROADCAST.requiredAuthority!],
  iconName: 'ic-broadcast',
  id: 'broadcast',
  labelId: 'sidebar.broadcast',
  url: BROADCAST.path
};

export const BROADCAST_CONTACT_SIDEBAR: SidebarItem = {
  authorityIds: [BROADCAST.requiredAuthority!, CONTACT.requiredAuthority!],
  children: [BROADCAST_SIDEBAR, CONTACT_SIDEBAR],
  icon: <BroadcastContactIcon />,
  iconName: 'ic-broadcastContact',
  id: 'broadcastContact',
  labelId: 'sidebar.broadcastContact'
};
