import * as React from 'react';
import { OrderLiveOrderIcon } from 'src/app/components/Icons/OrderLiveOrderIcon';
import {
  ADMIN_DEPOSIT,
  DEPOSIT,
  DRIVER_DEPOSIT,
  LIVE_ORDER,
  ORDER,
  ORDER_CONFIGURATION
} from 'src/app/constants/route';
import { SidebarItem } from '../sidebar';

const ORDER_SIDEBAR: SidebarItem = {
  authorityIds: [ORDER.requiredAuthority!],
  iconName: 'ic-order',
  id: 'order',
  labelId: 'sidebar.order',
  url: ORDER.path
};

const LIVE_ORDER_SIDEBAR: SidebarItem = {
  authorityIds: [LIVE_ORDER.requiredAuthority!],
  iconName: 'ic-live-order',
  id: 'liveOrder',
  labelId: 'sidebar.liveOrder',
  url: LIVE_ORDER.path
};

const DEPOSIT_SIDEBAR: SidebarItem = {
  authorityIds: [ADMIN_DEPOSIT.requiredAuthority!, DRIVER_DEPOSIT.requiredAuthority!],
  iconName: 'ic-payment-cod-order',
  id: 'deposit',
  labelId: 'sidebar.deposit',
  url: DEPOSIT.path
};

const ORDER_CONFIGURATION_SIDEBAR: SidebarItem = {
  authorityIds: [ORDER_CONFIGURATION.requiredAuthority!],
  iconName: 'ic-order-configuration',
  id: 'orderConfiguration',
  labelId: 'sidebar.orderConfiguration',
  url: ORDER_CONFIGURATION.path
};

export const ORDER_LIVE_ORDER_SIDEBAR: SidebarItem = {
  authorityIds: [
    ORDER.requiredAuthority!,
    LIVE_ORDER.requiredAuthority!,
    ADMIN_DEPOSIT.requiredAuthority!,
    DRIVER_DEPOSIT.requiredAuthority!,
    ORDER_CONFIGURATION.requiredAuthority!
  ],
  children: [ORDER_SIDEBAR, LIVE_ORDER_SIDEBAR, DEPOSIT_SIDEBAR, ORDER_CONFIGURATION_SIDEBAR],
  icon: <OrderLiveOrderIcon />,
  iconName: 'ic-order',
  id: 'orderLiveOrder',
  labelId: 'sidebar.orderLiveOrder'
};
