import * as React from 'react';
import { UserRoleIcon } from 'src/app/components/Icons';
import { ROLE, USER } from 'src/app/constants/route';
import { SidebarItem } from '../sidebar';

const ROLE_SIDEBAR: SidebarItem = {
  authorityIds: [ROLE.requiredAuthority!],
  iconName: 'ic-role',
  id: 'role',
  labelId: 'sidebar.role',
  url: ROLE.path
};

const USER_SIDEBAR: SidebarItem = {
  authorityIds: [USER.requiredAuthority!],
  iconName: 'ic-user',
  id: 'user',
  labelId: 'sidebar.user',
  url: USER.path
};

export const USER_ROLE_SIDEBAR: SidebarItem = {
  authorityIds: [ROLE.requiredAuthority!, USER.requiredAuthority!],
  children: [ROLE_SIDEBAR, USER_SIDEBAR],
  icon: <UserRoleIcon />,
  iconName: 'ic-user',
  id: 'userRole',
  labelId: 'sidebar.userRole'
};
