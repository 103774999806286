import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import { Box } from '@material-ui/core';
import * as React from 'react';
import { Mutation, MutationFn, Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { HasAnyAuthority } from 'src/app/components/HasAnyAuthority';
import { ConfigProps, withConfig } from 'src/app/components/WithConfig';
import { COLOR_PRIMARY_DARK } from 'src/app/constants/color';
import { DASHBOARD } from 'src/app/constants/route';
import { VerticalNavigationBarMenu } from 'src/app/views/VerticalNavigationBar/VerticalNavigationBarMenu';
import {
  FetchIsNavigationBarExpandedResult,
  IS_EXPANDED,
  TOGGLE_NAVIGATION_BAR,
  ToggleExpandNavigationBarVariables
} from 'src/platforms/config/navigationbar';
import { VerticalProfileNavigationBar } from '../ProfileNavigationBar/ProfileNavigationBar';

import { SIDEBAR_ITEM_TREE, SidebarItem } from './sidebar';

type VerticalNavigationBarProps = ConfigProps & RouteComponentProps<{}>;

const $VerticalNavigationBar: React.FC<VerticalNavigationBarProps> = ({ history }) => {
  const [isExpanded, setExpanded] = React.useState(false);
  const [reRender, setReRender] = React.useState(false);

  const handleExpanderClick = (e: any) => {
    e.preventDefault();
    setExpanded(!isExpanded);
  };

  const renderSidebar = (items: SidebarItem[]) =>
    items.map((item: SidebarItem) => (
      <Query query={IS_EXPANDED} variables={{ id: item.id }} key={item.id}>
        {({ data }) => (
          <Mutation<FetchIsNavigationBarExpandedResult, ToggleExpandNavigationBarVariables>
            mutation={TOGGLE_NAVIGATION_BAR}
            variables={{ id: item.id }}
            refetchQueries={[{ query: IS_EXPANDED, variables: { id: item.id } }]}
          >
            {toggleNavigationBarFn => (
              <div className="VerticalNavigationBar__indent">
                {item.authorityIds &&
                  item.authorityIds.length > 0 &&
                  renderWithHasAnyAuthority(item, data, toggleNavigationBarFn)}
                {!item.authorityIds && renderNavigationBar(item, data, toggleNavigationBarFn)}
              </div>
            )}
          </Mutation>
        )}
      </Query>
    ));

  const renderWithHasAnyAuthority = (
    item: SidebarItem,
    data: FetchIsNavigationBarExpandedResult,
    toggleNavigationBarFn: MutationFn<FetchIsNavigationBarExpandedResult, ToggleExpandNavigationBarVariables>
  ) => (
    <HasAnyAuthority authorities={item.authorityIds!}>
      {renderNavigationBar(item, data, toggleNavigationBarFn)}
    </HasAnyAuthority>
  );

  const renderNavigationBar = (
    item: SidebarItem,
    data: FetchIsNavigationBarExpandedResult,
    toggleNavigationBarFn: MutationFn<FetchIsNavigationBarExpandedResult, ToggleExpandNavigationBarVariables>
  ) => (
    <VerticalNavigationBarMenu>
      {item.url && renderLink(item.labelId, item.url, item.icon)}
      {!item.url && renderCollapsibleMenu(item.labelId, toggleNavigationBarFn, data.isExpanded, item.icon)}
      {item.children && data && data.isExpanded && renderSidebar(item.children)}
    </VerticalNavigationBarMenu>
  );

  const renderLink = (labelId: string, url: string, icon?: JSX.Element) => (
    <Link to={url}>
      {url === history.location.pathname || (url !== DASHBOARD.path && history.location.pathname.includes(url)) ? (
        <Box
          display="flex"
          component="span"
          mr="1rem"
          whiteSpace="nowrap"
          color={COLOR_PRIMARY_DARK}
          style={{ overflowX: 'auto' }}
        >
          {renderIcon(icon)}
          {renderLabel(labelId)}
        </Box>
      ) : (
        <Box display="flex" component="span" mr="1rem" whiteSpace="nowrap" style={{ overflowX: 'auto' }}>
          {renderIcon(icon)}
          {renderLabel(labelId)}
        </Box>
      )}
    </Link>
  );

  const renderCollapsibleMenu = (
    labelId: string,
    toggleNavigationBarFn: MutationFn<FetchIsNavigationBarExpandedResult, ToggleExpandNavigationBarVariables>,
    expanded: boolean,
    icon?: JSX.Element
  ) => (
    <VerticalNavigationBarMenu onClick={handleClick(toggleNavigationBarFn)}>
      {expanded}
      <a className={expanded ? 'VerticalNavigationBarMenu--expanded' : 'VerticalNavigationBarMenu--expandable'}>
        <Box display="flex" component="span" mr="1rem" whiteSpace="nowrap" style={{ overflowX: 'auto' }}>
          {renderIcon(icon)}
          {renderLabel(labelId)}
        </Box>
      </a>
    </VerticalNavigationBarMenu>
  );

  const renderIcon = (icon?: JSX.Element) =>
    icon ? (
      <Box width="2.5rem">
        <Box position="absolute" top="7px">
          {icon}
        </Box>
      </Box>
    ) : null;

  const renderLabel = (labelId: string) => (
    <Box>
      <FormattedMessage id={labelId}>{message => message}</FormattedMessage>
    </Box>
  );

  const handleClick = (
    toggleNavigationBarFn: MutationFn<FetchIsNavigationBarExpandedResult, ToggleExpandNavigationBarVariables>
  ) => (_: any) => {
    setReRender(!reRender);
    toggleNavigationBarFn();
  };

  return (
    <>
      <div className="VerticalNavigationBar__expander">
        <div className="VerticalNavigationBar__expander-logo" />
        <button
          type="button"
          className={
            'VerticalNavigationBar__expander-button' +
            (isExpanded ? ' VerticalNavigationBar__expander-button--active' : '')
          }
          onClick={handleExpanderClick}
        >
          <div className="VerticalNavigationBar__expander-bar VerticalNavigationBar__expander-bar-1" />
          <div className="VerticalNavigationBar__expander-bar VerticalNavigationBar__expander-bar-2" />
        </button>
      </div>
      <div className={`VerticalNavigationBar ${isExpanded ? 'VerticalNavigationBar--expanded' : ''}`}>
        <div className="VerticalNavigationBar__logo" />
        <VerticalProfileNavigationBar />
        <div className="VerticalNavigationBar__sidebar">{renderSidebar(SIDEBAR_ITEM_TREE)}</div>
      </div>
    </>
  );
};

export const VerticalNavigationBar: React.FC = () =>
  withConfig({
    Component: withRouter(withStyles(require('./VerticalNavigationBar.scss'))($VerticalNavigationBar))
  });
