import * as React from 'react';
import Helmet from 'react-helmet-async';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import { withAuth } from 'src/app/components/WithAuth';
import { MainPage } from 'src/app/views/MainPage';

export const Dashboard = () => withAuth({ Component: DashboardContainer, isAuthenticated: true });

@withStyles(require('./Dashboard.scss'))
class DashboardContainer extends React.Component {
  render() {
    return (
      <MainPage pageTitle="link.dashboard">
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
      </MainPage>
    );
  }
}
