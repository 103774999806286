import gql from 'graphql-tag';

export const FETCH_ME_QUERY = gql`
  query GetMe {
    me {
      id
      username
      name
      email
      authorities
    }
  }
`;

export interface FetchMeQueryResult {
  me?: {
    id: string;
    username: string;
    name: string;
    email: string;
    authorities: string[];
  };
}

export const LOGIN_QUERY = gql`
  mutation DoLogin($loginInput: LoginInput!) {
    doLogin(loginInput: $loginInput) {
      id
      username
      name
      email
      authorities
    }
  }
`;

export interface LoginQueryResult {
  doLogin: {
    id: string;
    username: string;
    name: string;
    email: string;
    authorities: string[];
  };
}

export interface LoginQueryVariables {
  loginInput: {
    username: string;
    password: string;
  };
}

export const LOGOUT_QUERY = gql`
  mutation DoLogout {
    doLogout
  }
`;
