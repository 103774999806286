import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import { LocationDescriptor } from 'history';
import { Redirect } from 'react-router';

interface StateProps {
  to?: LocationDescriptor;
  figure: 'FormRegular' | 'TableCompact' | 'LoginSubmit' | 'HeaderRegular';
  openLinkInNewTab?: boolean;
  color:
    | 'Positive'
    | 'PositiveInverse'
    | 'Negative'
    | 'NegativeInverse'
    | 'NeutralInverse'
    | 'DetailNeutral'
    | 'DetailNegative'
    | 'DetailWarning'
    | 'Hyperlink'
    | 'Success';
}

export interface ButtonState {
  isRedirect: boolean;
}

export type ButtonProps = StateProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

@withStyles(require('./Button.scss'))
export class Button extends React.Component<ButtonProps, ButtonState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isRedirect: false
    };
  }

  handleRedirect = (event: any) => {
    if (this.props.openLinkInNewTab || event.metaKey || event.ctrlKey) {
      window.open(this.props.to!.toString(), '_blank');
    } else {
      this.setState({
        isRedirect: true
      });
    }
  };

  render() {
    const { to, figure, color, title, onClick, type, openLinkInNewTab, ...rest } = this.props;

    if (this.state.isRedirect) {
      return <Redirect to={to!} push={true} />;
    }

    return (
      <button
        type={type || 'submit'}
        className={`Button ${figure} ${color}`}
        title={title}
        onClick={to ? this.handleRedirect : onClick}
        {...rest}
      >
        {this.props.children}
      </button>
    );
  }
}
