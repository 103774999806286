import React from 'react';
import { BranchIcon, CustomerIcon, ItemIcon } from 'src/app/components/Icons';
import { BRANCH, CUSTOMER, DASHBOARD, ITEM } from 'src/app/constants/route';
import { BROADCAST_CONTACT_SIDEBAR } from './SideBarItems/broadcastContact';
import { DRIVER_SIDEBAR } from './SideBarItems/driver';
import { ORDER_LIVE_ORDER_SIDEBAR } from './SideBarItems/orderLiveOrder';
import { USER_ROLE_SIDEBAR } from './SideBarItems/userRole';

export interface SidebarItem {
  authorityIds?: string[];
  children?: SidebarItem[];
  icon?: JSX.Element;
  iconName?: string;
  id: string;
  labelId: string;
  url?: string;
}

const DASHBOARD_SIDEBAR: SidebarItem = {
  iconName: 'ic-dashboard',
  id: 'dashboard',
  labelId: 'sidebar.dashboard',
  url: DASHBOARD.path
};

const BRANCH_SIDEBAR: SidebarItem = {
  authorityIds: [BRANCH.requiredAuthority!],
  icon: <BranchIcon />,
  iconName: 'ic-branch',
  id: 'branch',
  labelId: 'sidebar.branch',
  url: BRANCH.path
};

const ITEM_SIDEBAR: SidebarItem = {
  authorityIds: [ITEM.requiredAuthority!],
  iconName: 'ic-item',
  id: 'item',
  labelId: 'sidebar.item',
  icon: <ItemIcon />,
  url: ITEM.path
};

const CUSTOMER_SIDEBAR: SidebarItem = {
  authorityIds: [CUSTOMER.requiredAuthority!],
  iconName: 'ic-customer',
  id: 'customer',
  labelId: 'sidebar.customer',
  icon: <CustomerIcon />,
  url: CUSTOMER.path
};

export const SIDEBAR_ITEM_TREE: SidebarItem[] = [
  DASHBOARD_SIDEBAR,
  USER_ROLE_SIDEBAR,
  ORDER_LIVE_ORDER_SIDEBAR,
  BRANCH_SIDEBAR,
  ITEM_SIDEBAR,
  BROADCAST_CONTACT_SIDEBAR,
  CUSTOMER_SIDEBAR,
  DRIVER_SIDEBAR
];
