import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

type ChevronRightIconProps = SvgIconProps;

export const ChevronRightIcon: React.FC<ChevronRightIconProps> = props => (
  <SvgIcon viewBox="0 0 32 32" fill="none" {...props}>
    <path d="M18.629 15.997l-7.083-7.081L13.462 7l8.997 8.997L13.457 25l-1.916-1.916z" />
  </SvgIcon>
);
