import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

type ChevronDownIconProps = SvgIconProps;

export const ChevronDownIcon: React.FC<ChevronDownIconProps> = props => (
  <SvgIcon viewBox="0 0 32 32" fill="none" {...props}>
    <path d="M16.003 18.626l7.081-7.081L25 13.46l-8.997 8.998-9.003-9 1.917-1.916z" />
  </SvgIcon>
);
