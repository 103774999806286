import _ from 'lodash';
import React from 'react';
import { Query, QueryResult } from 'react-apollo';

import { FETCH_ME_QUERY, FetchMeQueryResult } from 'src/app/containers/Login/Login.queries';

interface StateProps {
  authorities: string[];
}

type HasAnyAuthorityProps = StateProps;

export class HasAnyAuthority extends React.Component<HasAnyAuthorityProps> {
  render() {
    return (
      <Query<FetchMeQueryResult> query={FETCH_ME_QUERY} fetchPolicy={'cache-only'}>
        {// tslint:disable-next-line:jsx-no-multiline-js
        ({ loading, data }: QueryResult<FetchMeQueryResult>) => {
          if (loading) {
            return null;
          }
          if (!data) {
            return null;
          }
          if (!data.me) {
            return null;
          }
          if (
            _.filter(this.props.authorities, (allowed: string) => data.me!.authorities.includes(allowed)).length > 0
          ) {
            return this.props.children;
          }
          return null;
        }}
      </Query>
    );
  }
}
