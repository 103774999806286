import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import { Box } from '@material-ui/core';
import ApolloClient from 'apollo-client';
import moment from 'moment';
import * as React from 'react';
import { ApolloConsumer, Mutation, MutationFn, Query } from 'react-apollo';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { BreakIcon, LogoutIcon, ProfileIcon } from 'src/app/components/Icons';
import { ChevronDownIcon } from 'src/app/components/Icons/ChevronDownIcon';
import { ChevronRightIcon } from 'src/app/components/Icons/ChevronRightIcon';
import { LOGIN, PROFILE } from 'src/app/constants/route';
import {
  DRIVER_OFFICE_HOUR_INFO,
  DriverOfficeHourInfoQueryResult,
  DriverStatus,
  NavigationBarDriverStatusDisplay
} from 'src/app/containers/authenticated/driver/Driver.queries';
import { FETCH_ME_QUERY, FetchMeQueryResult, LOGOUT_QUERY } from 'src/app/containers/Login/Login.queries';

import {
  BACK_FROM_BREAK_QUERY,
  BackFromBreakQueryResult,
  TAKE_BREAK_QUERY,
  TakeBreakQueryResult
} from './ProfileNavigationBar.queries';
import { BreakActionStatus, ProfileNavigationBarModal } from './ProfileNavigationBarModal';

const DRIVER_AUTHORITY = 'IS_DRIVER';

interface DriverProfileNavigationBarContainerProps {
  backFromBreakFn: MutationFn<BackFromBreakQueryResult>;
  endBreakTime: Date | null;
  endOfficeHourTime: Date | null;
  onBackFromBreakSuccess: () => void;
  onTakeBreakSuccess: () => void;
  t: 'driver';
  takeBreakFn: MutationFn<TakeBreakQueryResult>;
  type: 'horizontal' | 'vertical';
  startOfficeHourTime: Date | null;
  status: DriverStatus;
  userName: string;
}

interface NonDriverProfileNavigationBarContainerProps {
  t: 'nonDriver';
  type: 'horizontal' | 'vertical';
  userName: string;
}
type ProfileNavigationBarContainerProps =
  | DriverProfileNavigationBarContainerProps
  | NonDriverProfileNavigationBarContainerProps;

interface ProfileNavigationBarContainerState {
  breakActionStatus: BreakActionStatus;
  expanded: boolean;
}
class ProfileNavigationBarContainer extends React.Component<
  ProfileNavigationBarContainerProps & RouteComponentProps,
  ProfileNavigationBarContainerState
> {
  private readonly wrapperRef: any;
  constructor(props: ProfileNavigationBarContainerProps & RouteComponentProps) {
    super(props);
    this.state = {
      expanded: false,
      breakActionStatus: {
        t: 'idle'
      }
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.type === 'horizontal') {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    if (this.props.type === 'horizontal') {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  isDriverOffHour() {
    if (this.props.t !== 'driver') {
      throw new Error('Not a driver');
    }

    const { endOfficeHourTime, startOfficeHourTime } = this.props;
    if (!endOfficeHourTime || !startOfficeHourTime) {
      return true;
    }

    const now = moment();
    return now.isBefore(startOfficeHourTime) || now.isAfter(endOfficeHourTime);
  }

  renderDriverStatus() {
    return (
      <>
        {this.props.t === 'driver' && (
          <>
            {this.props.endBreakTime && (
              <div className="ProfileNavigationBar__user__status ProfileNavigationBar__user__status--off-hour-or-break">
                {NavigationBarDriverStatusDisplay.BREAK} sampai {moment(this.props.endBreakTime).format('HH:mm')}
              </div>
            )}
            {this.props.status === DriverStatus.ACTIVE && this.isDriverOffHour() && (
              <div className="ProfileNavigationBar__user__status ProfileNavigationBar__user__status--off-hour-or-break">
                {NavigationBarDriverStatusDisplay.OFF_HOUR}
              </div>
            )}
            {this.props.status === DriverStatus.ACTIVE && !this.isDriverOffHour() && (
              <div className="ProfileNavigationBar__user__status ProfileNavigationBar__user__status--active">
                {NavigationBarDriverStatusDisplay.ACTIVE}
              </div>
            )}
            {(this.props.status === DriverStatus.INACTIVE ||
              this.props.status === DriverStatus.SUSPENDED ||
              this.props.status === DriverStatus.IN_TROUBLE) && (
              <div className="ProfileNavigationBar__user__status ProfileNavigationBar__user__status--inactive-or-suspended">
                {NavigationBarDriverStatusDisplay[this.props.status]}
              </div>
            )}
          </>
        )}
      </>
    );
  }

  renderMenu() {
    return (
      <div className="ProfileNavigationBar__menu-container">
        <div className="ProfileNavigationBar__item">
          <Link to={PROFILE.path}>
            <Box display="flex">
              <ProfileIcon style={{ width: '13px' }} />
              <div className="ProfileNavigationBar__text-container">My Profile</div>
            </Box>
          </Link>
        </div>
        {this.props.t === 'driver' && this.props.status === DriverStatus.ACTIVE && !this.isDriverOffHour() && (
          <div className="ProfileNavigationBar__item" onClick={this.handleTakeBreak(this.props.takeBreakFn)}>
            <Box display="flex">
              <BreakIcon style={{ width: '13px' }} />
              <div className="ProfileNavigationBar__text-container">Take Break</div>
            </Box>
          </div>
        )}
        {this.props.t === 'driver' && this.props.status === DriverStatus.BREAK && (
          <div className="ProfileNavigationBar__item" onClick={this.handleBackFromBreak(this.props.backFromBreakFn)}>
            <Box display="flex">
              <BreakIcon style={{ width: '13px' }} />
              <div className="ProfileNavigationBar__text-container">Back From Break</div>
            </Box>
          </div>
        )}
        <div className="ProfileNavigationBar__item ProfileNavigationBar__item--logout">
          <ApolloConsumer>
            {// tslint:disable-next-line:jsx-no-multiline-js
            apolloClient => (
              <a onClick={this.handleLogout(apolloClient)}>
                <Box display="flex">
                  <LogoutIcon style={{ width: '13px' }} />
                  <div className="ProfileNavigationBar__text-container">Logout</div>
                </Box>
              </a>
            )}
          </ApolloConsumer>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="ProfileNavigationBar" tabIndex={-1} onClick={this.handleClickInside} ref={this.wrapperRef}>
          <div className="ProfileNavigationBar__user-container">
            <div className="ProfileNavigationBar__user">
              Hello, <b>{this.props.userName}</b>
              {this.renderDriverStatus()}
            </div>
            {this.state.expanded && <ChevronDownIcon className="ProfileNavigationBar__icon" />}
            {!this.state.expanded && <ChevronRightIcon className="ProfileNavigationBar__icon" />}
          </div>
          {this.state.expanded && this.renderMenu()}
        </div>
        <ProfileNavigationBarModal
          breakActionStatus={this.state.breakActionStatus}
          onCloseModal={this.handleCloseModal}
        />
      </>
    );
  }

  private handleClickOutside = (event: any) => {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        expanded: false
      });
    }
  };

  private handleClickInside = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  private handleCloseModal = () => {
    this.setState({
      breakActionStatus: {
        t: 'idle'
      }
    });
  };

  private handleLogout = (apolloClient: ApolloClient<any>) => () => {
    apolloClient.mutate({ mutation: LOGOUT_QUERY }).then(() => {
      apolloClient.resetStore();
      this.props.history.push(LOGIN.path);
    });
  };

  private handleTakeBreak = (takeBreakFn: MutationFn<TakeBreakQueryResult>) => (): Promise<void> =>
    takeBreakFn()
      .then(result => {
        if (!result) return;
        if (result.errors) {
          this.setState({
            breakActionStatus: {
              t: 'failed',
              message: result.errors[0].message
            }
          });
          return;
        }
        if (!result.data) return;
        if (!result.data.takeBreak) return;

        this.setState({
          breakActionStatus: {
            t: 'success',
            message: `You have taken a break until ${moment(result.data.takeBreak).format('HH:mm')}`
          }
        });

        if (this.props.t === 'driver') {
          this.props.onTakeBreakSuccess();
        }
      })
      .catch(error => {
        this.setState({
          breakActionStatus: {
            t: 'failed',
            message: error
          }
        });
      });

  private handleBackFromBreak = (backFromBreak: MutationFn<BackFromBreakQueryResult>) => (): Promise<void> =>
    backFromBreak()
      .then(result => {
        if (!result) return;
        if (result.errors) {
          this.setState({
            breakActionStatus: {
              t: 'failed',
              message: result.errors[0].message
            }
          });
          return;
        }
        if (!result.data) return;
        if (!result.data.backFromBreak) return;

        this.setState({
          breakActionStatus: {
            t: 'success',
            message: 'You are back from break now'
          }
        });

        if (this.props.t === 'driver') {
          this.props.onBackFromBreakSuccess();
        }
      })
      .catch(error => {
        this.setState({
          breakActionStatus: {
            t: 'failed',
            message: error
          }
        });
      });
}

interface ProfileNavigationBarQueryContainerProps {
  type: 'horizontal' | 'vertical';
}
// tslint:disable-next-line: max-classes-per-file
class ProfileNavigationBarQueryContainer extends React.Component<
  ProfileNavigationBarQueryContainerProps & RouteComponentProps
> {
  render() {
    return (
      <Query<FetchMeQueryResult> query={FETCH_ME_QUERY}>
        {({ data: fetchMeData }) => {
          if (!fetchMeData || !fetchMeData.me) return null;
          const me = fetchMeData.me;

          return (
            <>
              {me.authorities.includes(DRIVER_AUTHORITY) && (
                <Query<DriverOfficeHourInfoQueryResult> query={DRIVER_OFFICE_HOUR_INFO}>
                  {({ data: driverOfficeHourInfoData, refetch }) => {
                    if (!driverOfficeHourInfoData || !driverOfficeHourInfoData.driverOfficeHourInfo) return null;
                    const { driverOfficeHourInfo } = driverOfficeHourInfoData;

                    return (
                      <Mutation mutation={TAKE_BREAK_QUERY}>
                        {takeBreakFn => (
                          <Mutation mutation={BACK_FROM_BREAK_QUERY}>
                            {backFromBreakFn => (
                              <ProfileNavigationBarContainer
                                {...this.props}
                                backFromBreakFn={backFromBreakFn}
                                endBreakTime={driverOfficeHourInfo.endBreakTime}
                                endOfficeHourTime={driverOfficeHourInfo.endOfficeHourTime}
                                onBackFromBreakSuccess={refetch}
                                onTakeBreakSuccess={refetch}
                                t="driver"
                                takeBreakFn={takeBreakFn}
                                startOfficeHourTime={driverOfficeHourInfo.startOfficeHourTime}
                                status={driverOfficeHourInfo.status}
                                userName={me.username}
                              />
                            )}
                          </Mutation>
                        )}
                      </Mutation>
                    );
                  }}
                </Query>
              )}
              {!me.authorities.includes(DRIVER_AUTHORITY) && (
                <ProfileNavigationBarContainer {...this.props} t="nonDriver" userName={me.username} />
              )}
            </>
          );
        }}
      </Query>
    );
  }
}

@withStyles(require('./VerticalProfileNavigationBar.scss'))
// tslint:disable-next-line: max-classes-per-file
class VerticalProfileNavigationBarContainer extends React.Component<RouteComponentProps> {
  render() {
    return <ProfileNavigationBarQueryContainer {...this.props} type="vertical" />;
  }
}

@withStyles(require('./HorizontalProfileNavigationBar.scss'))
// tslint:disable-next-line: max-classes-per-file
class HorizontalProfileNavigationBarContainer extends React.Component<RouteComponentProps> {
  render() {
    return <ProfileNavigationBarQueryContainer {...this.props} type="horizontal" />;
  }
}

export const VerticalProfileNavigationBar = withRouter(VerticalProfileNavigationBarContainer);
export const HorizontalProfileNavigationBar = withRouter(HorizontalProfileNavigationBarContainer);
