import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import * as React from 'react';
import ReactModal from 'react-modal';
import { Button } from 'src/app/components/forms/Button';

import { InternalReactModal } from 'src/app/components/InternalReactModal';

interface IdleStatus {
  t: 'idle';
}
interface FailedStatus {
  message: string;
  t: 'failed';
}
interface SuccessStatus {
  message: string;
  t: 'success';
}
export type BreakActionStatus = IdleStatus | FailedStatus | SuccessStatus;

interface ProfileNavigationBarModalProps {
  breakActionStatus: BreakActionStatus;
  onCloseModal: () => void;
}

class ProfileNavigationBarModalContainer extends React.Component<ProfileNavigationBarModalProps> {
  componentWillMount() {
    ReactModal.setAppElement('body');
  }

  render() {
    return (
      <InternalReactModal
        className="ProfileNavigationBarModal__content"
        isOpen={this.props.breakActionStatus.t !== 'idle'}
        portalClassName="ProfileNavigationBarModal"
      >
        {this.props.breakActionStatus.t === 'failed' && (
          <>
            <p className="ProfileNavigationBarModal__text ProfileNavigationBarModal__text--error">Failed</p>
            <p className="ProfileNavigationBarModal__text">{this.props.breakActionStatus.message}</p>
          </>
        )}
        {this.props.breakActionStatus.t === 'success' && (
          <>
            <p className="ProfileNavigationBarModal__text ProfileNavigationBarModal__text--success">Sucess</p>
            <p className="ProfileNavigationBarModal__text">{this.props.breakActionStatus.message}</p>
          </>
        )}
        <Button figure="HeaderRegular" color="Positive" onClick={this.props.onCloseModal}>
          OK
        </Button>
      </InternalReactModal>
    );
  }
}

export const ProfileNavigationBarModal = withStyles(require('./ProfileNavigationBarModal.scss'))(
  ProfileNavigationBarModalContainer
);
