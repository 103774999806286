import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import * as React from 'react';

interface VerticalNavigationBarMenuProps {
  onClick?: () => void;
}

const $VerticalNavigationBarMenu: React.FC<VerticalNavigationBarMenuProps> = ({ children, onClick }) => (
  <div className="VerticalNavigationBarMenu" onClick={onClick} role="button">
    {children}
  </div>
);

export const VerticalNavigationBarMenu = withStyles(require('./VerticalNavigationBarMenu.scss'))(
  $VerticalNavigationBarMenu
);
