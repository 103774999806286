import gql from 'graphql-tag';

import { DaysOfWeek } from 'src/app/constants/dayOfWeeks';
import { PageInfo } from 'src/app/constants/query/paging/PageInfo';
import { BranchQueryResult } from '../branch/Branch.queries';
import { UserQueryResult } from '../user/User.queries';

export interface DriverQueryResult {
  driver: UserQueryResult;
  driverId: number;
  driverOperationals: DriverOperationalQueryResult[];
  id: string;
  status: DriverStatus;
}

export enum DriverStatus {
  ACTIVE = 'ACTIVE',
  BREAK = 'BREAK',
  INACTIVE = 'INACTIVE',
  IN_TROUBLE = 'IN_TROUBLE',
  SUSPENDED = 'SUSPENDED'
}

export enum DriverStatusDisplay {
  ACTIVE = 'Active',
  BREAK = 'Take Break',
  INACTIVE = 'Inactive',
  IN_TROUBLE = 'In Trouble',
  SUSPENDED = 'Suspended'
}

export enum NavigationBarDriverStatusDisplay {
  ACTIVE = 'Aktif',
  BREAK = 'Istirahat',
  INACTIVE = 'Tidak Aktif',
  IN_TROUBLE = 'In Trouble',
  OFF_HOUR = 'Di Luar Jam Kerja',
  SUSPENDED = 'Ditanggungkan'
}

export interface DriverOperationalQueryResult {
  branch: {
    name: string;
  };
  branchId: number;
  day: DaysOfWeek;
  driverId: number;
  end: string;
  id: string;
  start: string;
}

export const FETCH_DRIVER_PAGE_QUERY = gql`
  query fetchDrivers($pageInput: PageInput) {
    driverPage(pageInput: $pageInput) {
      currentPage
      totalPages
      results {
        id
        driver {
          id
          name
        }
        driverOperationals {
          branch {
            id
            name
          }
          day
          end
          id
          start
        }
        status
      }
    }
  }
`;

export type FetchDriverPageQueryResult = PageInfo<DriverQueryResult>;

export interface DriverOperationalQueryVariables {
  branchId: number;
  day: DaysOfWeek;
  start: string;
  end: string;
}

export const FETCH_CREATE_DRIVER_FORM_COMPONENT_QUERY = gql`
  query fetchDriverFormComponent {
    driverUsers {
      id
      name
    }
    branchesAll {
      id
      name
    }
  }
`;

export interface FetchCreateDriverFormComponentQueryResult {
  driverUsers: UserQueryResult[];
  branchesAll: BranchQueryResult[];
}

export const CREATE_DRIVER_QUERY = gql`
  mutation CreateDriver($input: CreateDriverInput!) {
    createDriver(input: $input) {
      id
    }
  }
`;

export interface CreateDriverQueryResult {
  createDriver: {
    id: string;
  };
}

export interface CreateDriverQueryVariables {
  input: {
    driverId: number;
    driverOperationals: DriverOperationalQueryVariables[];
    status: DriverStatus;
  };
}

export const FETCH_UPDATE_DRIVER_FORM_COMPONENT_QUERY = gql`
  query fetchUpdateDriverFormComponent($id: ID!) {
    driver(id: $id) {
      driver {
        id
        name
      }
      driverId
      driverOperationals {
        branchId
        day
        end
        id
        start
      }
      id
      status
    }
    branchesAll {
      id
      name
    }
  }
`;

export interface FetchUpdateDriverFormComponentQueryResult {
  driver: DriverQueryResult;
  driverUsers: UserQueryResult[];
  branchesAll: BranchQueryResult[];
}

export const UPDATE_DRIVER_QUERY = gql`
  mutation updateDriverOperationals($id: ID!, $inputs: [DriverOperationalInput!]) {
    updateDriverOperationals(id: $id, inputs: $inputs) {
      id
    }
  }
`;

export interface UpdateDriverQueryResult {
  updateDriverOperationals: {
    id: string;
  };
}

export interface UpdateDriverQueryVariables {
  id: string;
  inputs: DriverOperationalQueryVariables[];
}

export const ACTIVATE_DRIVER_QUERY = gql`
  mutation ActivateDriver($id: ID!) {
    activateDriver(id: $id) {
      id
    }
  }
`;

export interface ActivateDriverQueryResult {
  activateDriver: boolean;
}

export interface ActivateDriverQueryVariables {
  id: string;
}

export const DEACTIVATE_DRIVER_QUERY = gql`
  mutation DeactivateDriver($id: ID!) {
    deactivateDriver(id: $id) {
      id
    }
  }
`;

export interface DeactivateDriverQueryResult {
  deactivateDriver: boolean;
}

export interface DeactivateDriverQueryVariables {
  id: string;
}

export const REACTIVATE_DRIVER_QUERY = gql`
  mutation ReactivateDriver($id: ID!) {
    reactivateDriver(id: $id) {
      id
    }
  }
`;

export interface ReactivateDriverQueryResult {
  reactivateDriver: boolean;
}

export interface ReactivateDriverQueryVariables {
  id: string;
}

export const IN_TROUBLE_DRIVER_QUERY = gql`
  mutation InTroubleDriver($id: ID!) {
    inTroubleDriver(id: $id) {
      id
    }
  }
`;

export interface InTroubleDriverQueryResult {
  inTroubleDriver: boolean;
}

export interface InTroubleDriverQueryVariables {
  id: string;
}

export const DRIVER_OFFICE_HOUR_INFO = gql`
  query driverOfficeHourInfo {
    driverOfficeHourInfo {
      branchId
      endBreakTime
      endOfficeHourTime
      isOrderCompleted
      startOfficeHourTime
      status
    }
  }
`;

export interface DriverOfficeHourInfoQueryResult {
  driverOfficeHourInfo?: {
    branchId?: string;
    endBreakTime: Date | null;
    endOfficeHourTime: Date | null;
    isOrderCompleted: boolean;
    startOfficeHourTime: Date | null;
    status: DriverStatus;
  };
}
