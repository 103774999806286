import * as React from 'react';
import { DriverIcon } from 'src/app/components/Icons';
import { DRIVER, DRIVER_DASHBOARD, DRIVER_FEE } from 'src/app/constants/route';
import { SidebarItem } from '../sidebar';

const DRIVER_SCHEDULE_SIDEBAR: SidebarItem = {
  authorityIds: [DRIVER.requiredAuthority!],
  iconName: 'ic-driverSchedule',
  id: 'driverSchedule',
  labelId: 'sidebar.driverSchedule',
  url: DRIVER.path
};

const DRIVER_FEE_SIDEBAR: SidebarItem = {
  authorityIds: [DRIVER_FEE.requiredAuthority!],
  iconName: 'ic-driverFee',
  id: 'driverFee',
  labelId: 'sidebar.driverFee',
  url: DRIVER_FEE.path
};

const DRIVER_DASHBOARD_SIDEBAR: SidebarItem = {
  authorityIds: [DRIVER_DASHBOARD.requiredAuthority!],
  iconName: 'ic-driverDashboard',
  id: 'driverDashboard',
  labelId: 'sidebar.driverDashboard',
  url: DRIVER_DASHBOARD.path
};

export const DRIVER_SIDEBAR: SidebarItem = {
  authorityIds: [DRIVER.requiredAuthority!, DRIVER_FEE.requiredAuthority!, DRIVER_DASHBOARD.requiredAuthority!],
  children: [DRIVER_SCHEDULE_SIDEBAR, DRIVER_FEE_SIDEBAR, DRIVER_DASHBOARD_SIDEBAR],
  icon: <DriverIcon />,
  iconName: 'ic-driver',
  id: 'driver',
  labelId: 'sidebar.driver'
};
