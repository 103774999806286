import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import { FormattedMessage } from 'react-intl';

interface MainPageProps {
  pageBreadcrumbs?: JSX.Element[];
  pageTitle?: string;
  withoutPadding?: boolean;
  titleValue?: any;
}

@withStyles(require('./MainPage.scss'))
export class MainPage extends React.Component<MainPageProps> {
  getPageBreadcrumbs() {
    if (this.props.pageBreadcrumbs !== undefined) {
      return this.props.pageBreadcrumbs.map((b, i) => (
        <div className="MainPage__breadcrumb" key={'breadcrumb-' + i}>
          {b}
        </div>
      ));
    }
    return undefined;
  }

  getPageTitle() {
    if (this.props.pageTitle !== undefined) {
      return <FormattedMessage id={this.props.pageTitle} tagName="h1" values={this.props.titleValue} />;
    }
    return undefined;
  }

  render() {
    return (
      <div className={'MainPage' + (this.props.withoutPadding ? ' MainPage--without-padding' : '')}>
        <div className="MainPage__breadcrumbs">{this.getPageBreadcrumbs()}</div>
        {this.getPageTitle()}
        {this.props.children}
      </div>
    );
  }
}
