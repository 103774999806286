import React from 'react';
import { Query } from 'react-apollo';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';

import _ from 'lodash';
import { VerticalNavigationBar } from 'src/app/views/VerticalNavigationBar/VerticalNavigationBar';
import { DASHBOARD, LOGIN } from '../constants/route';
import { FETCH_ME_QUERY, FetchMeQueryResult } from '../containers/Login/Login.queries';
import { HorizontalNavigationBar } from '../views/HorizontalNavigationBar/HorizontalNavigationBar';
import { OrderTrackingUpdater } from '../views/OrderTrackingUpdater/OrderTrackingUpdater';

interface WithAuthProps {
  Component: React.ComponentType<any>;
  isAuthenticated: boolean;
  componentProps?: any;
  requiredAuthority?: string | string[];
}

type AuthRouteProps = WithAuthProps & RouteComponentProps<any>;

class AuthRoute extends React.Component<AuthRouteProps> {
  render() {
    return (
      <Query<FetchMeQueryResult> query={FETCH_ME_QUERY} fetchPolicy={'network-only'}>
        {({ loading, data }) => (loading ? null : this.handleRender(data))}
      </Query>
    );
  }

  private readonly handleRender = (data?: FetchMeQueryResult) => {
    const { Component, isAuthenticated, location } = this.props;

    if (data && data.me) {
      if (isAuthenticated) {
        if (
          !this.props.requiredAuthority ||
          (this.props.requiredAuthority &&
            (data.me.authorities.includes(this.props.requiredAuthority.toString()) ||
              _.filter(this.props.requiredAuthority, (required: string) => data.me!.authorities.includes(required))
                .length > 0))
        ) {
          return (
            <div>
              <VerticalNavigationBar />
              <HorizontalNavigationBar />
              <OrderTrackingUpdater me={{ id: data.me.id, authorities: data.me.authorities }} />
              <Component {...this.props.componentProps} me={{ id: data.me.id, authorities: data.me.authorities }} />
            </div>
          );
        } else {
          return <Redirect to={{ pathname: DASHBOARD.path, state: { from: location } }} />;
        }
      } else {
        return <Redirect to={{ pathname: DASHBOARD.path, state: { from: location } }} />;
      }
    } else {
      if (isAuthenticated) {
        return <Redirect to={{ pathname: LOGIN.path, state: { from: location } }} />;
      } else {
        return <Component {...this.props.componentProps} />;
      }
    }
  };
}

const ConnectedAuthRoute = withRouter(AuthRoute);

export const withAuth = (props: WithAuthProps) => <ConnectedAuthRoute {...props} />;

export interface AuthProps {
  me: {
    id: string;
    authorities: string[];
  };
}
