import * as React from 'react';
import { Mutation } from 'react-apollo';
import {
  UPDATE_ORDER_TRACKING_QUERY,
  UpdateOrderTrackingQueryResult,
  UpdateOrderTrackingQueryVariables
} from './OrderTrackingUpdater.queries';

const DRIVER_AUTHORITY = 'IS_DRIVER';
const UPDATE_INTERVAL = 2000;

interface OrderTrackingUpdaterProps {
  me: {
    authorities: string[];
    id: string;
  };
}

export const OrderTrackingUpdater = ({ me }: OrderTrackingUpdaterProps) => {
  const updater = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    return () => {
      if (updater.current) {
        clearInterval(updater.current);
      }
    };
  }, []);

  return me.authorities.includes(DRIVER_AUTHORITY) ? (
    <Mutation<UpdateOrderTrackingQueryResult, UpdateOrderTrackingQueryVariables> mutation={UPDATE_ORDER_TRACKING_QUERY}>
      {updateOrderTrackingFn => {
        if (updater.current) {
          clearInterval(updater.current);
        }
        updater.current = setInterval(() => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
              updateOrderTrackingFn({
                variables: {
                  userId: me.id,
                  mapCoordinate: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                  }
                }
              });
            });
          }
        }, UPDATE_INTERVAL);
        return null;
      }}
    </Mutation>
  ) : null;
};
