import gql from 'graphql-tag';

export const UPDATE_ORDER_TRACKING_QUERY = gql`
  mutation updateOrderTracking($userId: ID!, $mapCoordinate: MapCoordinateInput!) {
    updateOrderTracking(userId: $userId, mapCoordinate: $mapCoordinate) {
      lat
      lng
    }
  }
`;

export interface UpdateOrderTrackingQueryResult {
  updateOrderTracking?: {
    lat: number;
    lng: number;
  };
}

export interface UpdateOrderTrackingQueryVariables {
  userId: string;
  mapCoordinate: {
    lat: number;
    lng: number;
  };
}
